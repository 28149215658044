.btn__scroll_top {
  background-color: #ae2021;
  background-image: url(/public/img/other/arrow_top.svg);
  background-size: cover;
  background-position: center center;
  width: 70px;
  height: 70px;
  color: #ae2021;
  border: 1px solid #ae2021;
  border-radius: 20%;
  cursor: pointer;
  transition: all 0.3s;
  position: fixed;
  bottom: 20px;
  right: 20px;

  &:hover {
    background-color: #921b1c;
    border-radius: 10%;
  }
}

.btn__scroll_top.visible {
  opacity: 1;
  pointer-events: auto;
}

@media (width <= 900px) {
  .btn__scroll_top {
    width: 50px;
    height: 50px;
  }

  .btn__scroll_top:hover {
    background-color: #ae2021;
    border-radius: 20%;
  }
}
