section.main-screen__section {
  padding: 0;
}

.main-screen__section {
  position: relative;
}

.main-screen__img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.slogan__main-screen {
  position: absolute;
  top: 280px;
  left: 100px;
  font-size: clamp(32px, 3vw, 64px);
  text-transform: uppercase;
  max-width: 840px;
  color: var(--font-color-white);
}

.catalog__btn {
  position: absolute;
  top: 450px;
  left: 100px;
  padding: 23px 72px;
  font-size: clamp(16px, 1.2vw, 20px);
  text-transform: uppercase;
  width: 240px;
  background-color: var(--button-background-color);
  border: 1px solid var(--button-color-red);
  border-radius: 20px;
  color: var(--font-color-white);
  transition: all 0.3s;
}

.catalog__btn:hover {
  background-color: var(--button-color-hover);
  border: 1px solid var(--button-color-hover);
  border-radius: 10px;
}

@media (width <= 2100px) {
  .catalog__btn {
    top: 520px;
  }
}

@media (width <= 2000px) {
  .catalog__btn {
    top: 450px;
  }
}

@media (width <= 1450px) {
  .slogan__main-screen,
  .catalog__btn {
    left: 50px;
  }

  .catalog__btn {
    top: 400px;
  }
}

@media (width <= 1190px) {
  .slogan__main-screen {
    max-width: 700px;
  }
}

@media (width <= 1050px) {
  .catalog__btn:hover {
    background-color: var(--button-background-color);
    border-radius: 20px;
  }
}

@media (width <= 500px) {
  .catalog__btn {
    top: 450px;
  }
}

@media (width <= 460px) {
  .catalog__btn {
    top: 450px;
  }
}
