.map__contact {
  margin-top: 50px;
  background-color: #d9d9d9;
  height: 500px;
  text-align: center;
  line-height: 500px;
  border-radius: 20px;
  font-size: 30px;
  text-transform: uppercase;
}

@media (width <= 1350px) {
  .map__contact {
    margin-top: 20px;
  }
}

@media (width <= 900px) {
  .map__contact {
    display: none;
  }
}
