.contacts__section {
  margin-top: 40px;
}

.contacts__items {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.contacts__item {
  border: 1px solid var(--line-color);
  padding: 30px 20px;
  align-items: center;
  border-radius: 10px;
}

.contacts__item_title {
  text-transform: uppercase;
  color: #ae2021;
  margin-bottom: 20px;
}

.contacts__item_name {
  font-size: 18px;
  margin-bottom: 20px;
}

.contacts__item_address {
  max-width: 350px;
  font-style: normal;
  font-size: 16px;
}

@media (width <= 900px) {
  .contacts__items {
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }

  .contacts__item_title {
    margin-bottom: 10px;
  }

  .contacts__item_name {
    margin-bottom: 10px;
  }
}

@media (width <= 650px) {
  .contacts__items {
    grid-template-columns: repeat(1, 100%);
  }
}
