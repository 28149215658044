footer {
  background-color: #dfdfdf;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding-top: 45px;
  padding-bottom: 175px;
}

.logo {
  width: 250px;
  height: 53px;
}

.right__footer {
  font-size: clamp(10px, 1.5vw, 16px);
  margin-top: 20px;
}

.nav__footer {
  display: flex;
  flex-direction: column;
}

.content__footer {
  display: flex;
  gap: 230px;
}

.link__footer {
  font-size: clamp(12px, 1.5vw, 18px);
  margin-top: 20px;
}

.social-title__footer {
  font-size: clamp(18px, 1vw, 24px);
}

.links__social {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.youtube__footer {
  display: inline-block;
  background-image: url(/public/img/other/youtube.png);
  background-size: cover;
  background-position: center center;
  width: 40px;
  height: 40px;
}

.whatsapp__footer {
  display: inline-block;
  background-image: url(/public/img/other/whatsapp.png);
  background-size: cover;
  background-position: center center;
  width: 40px;
  height: 40px;
}

.tg__footer {
  display: inline-block;
  background-image: url(/public/img/other/telegram.png);
  background-size: cover;
  background-position: center center;
  width: 40px;
  height: 40px;
}

@media (width <= 1400px) {
  .content__footer {
    display: flex;
    gap: 100px;
  }
}

@media (width <= 1350px) {
  .logo {
    width: 200px;
  }
}

@media (width <= 1050px) {
  .right__footer,
  .links__social {
    margin-top: 0;
  }

  .youtube__footer,
  .whatsapp__footer,
  .tg__footer {
    width: 30px;
    height: 30px;
  }
}

@media (width <= 900px) {
  .logo {
    width: 150px;
  }
}

@media (width <= 860px) {
  .footer {
    flex-direction: column;
    text-align: center;
    padding-bottom: 70px;
  }

  .logo__footer {
    margin-top: 5px;
  }

  .content__footer {
    margin-top: 20px;
    flex-direction: column;
    row-gap: 20px;
    gap: 50px;
  }

  .social__footer {
    margin-top: 20px;
  }

  .link__footer {
    margin-top: 10px;
  }

  .links__social {
    justify-content: center;
    gap: 10px;
  }
}
