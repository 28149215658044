:root {
  font-family: Arial, Helvetica, sans-serif;
  --font-size: clamp(10px, 1.5vw, 20px);
  --button-background-color: #c20d0e;
  --font-color-white: white;
  --button-color-red: #c20d0e;
  --button-color-hover: #921b1c;
  --font-color-black: #000000;
  --line-color: #ae2021;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: #ededed;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2) inset;
}

::-webkit-scrollbar-thumb {
  background: #921b1c;
  border-radius: 5px;
  border: 1px solid rgb(169, 169, 169);
  transition: all 0.5s;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

a {
  color: var(--font-color);
  text-decoration: none;
}

td {
  font-size: var(--font-size-table);
  padding-bottom: 15px;
}

header,
section,
footer {
  padding: 0 100px;
}

section {
  margin-bottom: 200px;
}

@media (width <= 1450px) {
  header,
  section,
  footer {
    padding: 0 50px;
  }
}

/* Mobile adaptation */
@media (width <= 500px) {
  header,
  section,
  footer {
    padding: 0 20px;
  }
}
