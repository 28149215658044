.no_page__section {
  margin-top: 200px;
}

.content__404 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no_page__code {
  color: #c20d0e;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 180px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 9px;
}

.no_page__title {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: 600;
}

.btn__home {
  border: 1px solid #c20d0e;
  padding: 15px 50px;
  color: #c20d0e;
  border-radius: 10px;
  font-size: 24px;
  margin-top: 100px;
  transition: all 0.3s;

  &:hover {
    background-color: #c20d0e;
    color: white;
  }
}

@media (width <= 900px) {
  .btn__home:hover {
    background-color: white;
    color: #c20d0e;
  }
}
