/* Main screen */
section.main-screen__section {
  padding: 0;
}

.main-screen__section {
  position: relative;
}

/* About section */
.title__about,
.title__category {
  font-size: clamp(26px, 2vw, 36px);
  text-transform: uppercase;
  font-weight: normal;
}

.title__about::after {
  content: "";
  display: block;
  width: 145px;
  height: 5px;
  background-color: var(--line-color);
  margin-top: 10px;
}

.title__category::after {
  content: "";
  display: block;
  width: 110px;
  height: 5px;
  background-color: var(--line-color);
  margin-top: 10px;
}

/* Category section */
.items__category {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 40px;
}

@media (width <= 1500px) {
  .items__category {
    display: grid;
    grid-template-columns: repeat(2, 560px);
  }
}

@media (width <= 1350px) {
  .items__category {
    margin-top: 20px;
  }
}

@media (width <= 1200px) {
  .items__category {
    grid-template-columns: repeat(2, 500px);
  }
}

@media (width <= 1050px) {
  .items__category {
    grid-template-columns: repeat(2, 400px);
  }
}

@media (width <= 1000px) {
  .items__category {
    display: flex;
    flex-direction: column;
  }
}
