header {
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 250px;
  height: 53px;
}

.items-text__nav {
  display: flex;
  align-items: center;
  gap: 70px;
}

.item__nav {
  list-style: none;
  font-size: var(--font-size);
}

.item__nav_active {
  background-color: #ae2021;
  color: white;
  padding: 8px 10px;
  border-radius: 5px;
  transition: all 0.3s;
}

.dropdown__toggle {
  display: inline-block;
  background-image: url(/public/img/other/arrow_down.svg);
  background-size: cover;
  background-position: center center;
  width: 15px;
  height: 15px;
  margin-left: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.dropdown__toggle:active {
  transform: rotate(180deg);
}

.item__nav_drop {
  list-style: none;
}

.dropdown__menu {
  display: block;
  position: absolute;
  background-color: white;
  border: 1px solid #ae2021;
  border-radius: 5px;
  padding: 10px;
  z-index: 10;
}

.dropdown__item_default {
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  color: black;
}

.dropdown__item_active {
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  color: white;
  background-color: #ae2021;
  border-radius: 5px;
}

.item__nav:last-child {
  font-size: clamp(14px, 1.8vw, 24px);
}

.active__item {
  text-decoration: underline;
  text-decoration-line: black;
  font-size: clamp(10px, 1.5vw, 20px);
}

.open {
  display: flex !important;
}

.burger {
  display: none;
  position: relative;
  z-index: 5;
  align-items: center;
  justify-content: flex-end;
  width: 30px;
  height: 20px;
}

.burger span {
  height: 3px;
  width: 80%;
  transform: scale(1);
  background-color: var(--button-background-color);
}

.burger::before,
.burger::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: var(--button-background-color);
  transition: all 0.3s ease 0.2s;
}

.burger::before {
  top: 0;
}

.burger::after {
  bottom: 0;
}

.burger.active span {
  transform: scale(0);
}

.burger.active::before {
  top: 50%;
  transform: rotate(-45deg) translate(0, -50%);
}

.burger.active::after {
  top: 50%;
  transform: rotate(45deg) translate(0, 50%);
}

@keyframes burgerAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (width <= 1500px) {
  .items-text__nav {
    gap: 50px;
  }
}

@media (width <= 1350px) {
  .logo {
    width: 200px;
  }
}

@media (width <= 1000px) {
  .items-text__nav {
    gap: 30px;
  }
}

@media (width <= 930px) {
  .header {
    margin: 10px 0;
  }

  .logo {
    width: 150px;
  }

  .burger {
    display: flex;
  }

  .nav.open {
    display: flex;
  }

  .nav {
    display: none;
    flex-direction: column;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    overflow-y: auto;
    padding: 50px 40px;
    row-gap: 20px;
    background-color: #fff;
    animation: burgerAnimation 0.4s;
  }

  .items-text__nav {
    flex-direction: column;
    gap: 15px;
  }

  .item__nav {
    font-size: 14px;
  }
}
