.content__about {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.text-content__about {
  font-size: clamp(14px, 1.2vw, 20px);
  max-width: 800px;
  text-align: justify;
}

.text-content__about p:nth-child(2),
.text-content__about p:last-child {
  margin-top: 55px;
}

.img__about {
  width: 800px;
  height: 420px;
  border-radius: 10px;
  object-fit: cover;
}

@media (width <= 1830px) {
  .img__about {
    width: 700px;
  }
}

@media (width <= 1800px) {
  .text-content__about {
    max-width: 600px;
  }

  .text-content__about p:nth-child(2),
  .text-content__about p:last-child {
    margin-top: 20px;
  }
}

@media (width <= 1540px) {
  .text-content__about {
    max-width: 500px;
  }
}

@media (width <= 1400px) {
  .img__about {
    width: 600px;
  }
}

@media (width <= 1350px) {
  .content__about {
    margin-top: 20px;
  }

  .text-content__about {
    max-width: 400px;
    text-align: justify;
  }

  .text-content__about p:nth-child(2),
  .text-content__about p:last-child {
    margin-top: 30px;
  }
}

@media (width <= 1100px) {
  .content__about {
    flex-direction: column-reverse;
  }

  .img__about {
    width: 100%;
    height: 50%;
  }

  .text-content__about {
    margin-top: 20px;
    max-width: 100%;
  }

  .text-content__about p:nth-child(2),
  .text-content__about p:last-child {
    margin-top: 20px;
  }
}
