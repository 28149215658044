.item__category {
  border: 1px solid var(--line-color);
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  transition: all 0.3s;
}

.item__category:hover {
  border-radius: 10px;
  transform: scale(1.02);
}

.item__category img {
  width: 400px;
  /* height: 260px; */
  margin: 0 auto;
  transition: all 0.3s;
}

.item__category img:hover {
  transform: scale(1.03);
}

.name__category {
  font-size: clamp(18px, 1vw, 24px);
}

.name__category::after {
  content: "";
  display: block;
  width: calc(100% - 50%);
  height: 3px;
  background-color: var(--line-color);
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.text__category {
  font-size: clamp(12px, 1.2vw, 16px);
  font-weight: 200;
  text-align: justify;
  max-width: 530px;
}

@media (width <= 1350px) {
  .item__category img {
    width: 300px;
  }
}

@media (width <= 1050px) {
  .item__category:hover {
    border-radius: 20px;
    transform: scale(1);
  }

  .item__category img:hover {
    transform: scale(1);
  }
}

@media (width <= 460px) {
  .item__category img {
    width: 250px;
  }
}
