:root {
  font-family: Arial, Helvetica, sans-serif;
  --font-size: clamp(14px, 1.5vw, 20px);
  --font-size-table: clamp(14px, 1.5vw, 18px);
  --font-size-table-head: clamp(14px, 1.5vw, 20px);
  --font-size-title-table: clamp(18px, 1.2vw, 24px);
  --button-background-color: #c20d0e;
  --font-color-white: white;
  --button-color-red: #c20d0e;
  --button-color-hover: #921b1c;
  --font-color-black: #000000;
  --line-color: #ae2021;
}
/* Section short-description */
.short-description {
  margin-top: 100px;
}

.title__short-description {
  font-size: clamp(26px, 2vw, 36px);
  text-transform: uppercase;
  font-weight: normal;

  &:after {
    content: "";
    display: block;
    width: 100px;
    height: 5px;
    background-color: var(--line-color);
    margin-top: 10px;
  }
}

.content-hero__short-description {
  display: flex;
  gap: 20px;
}

.content-hero__short-description img {
  width: 1100px;
}

.short-start {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 140px;
  flex-wrap: wrap;
}

.title__short-start {
  font-size: var(--font-size-title-table);
}

.btn__short-start {
  width: 200px;
  height: 35px;
  border: 1px solid var(--button-color-red);
  color: var(--button-color-red);
  background-color: transparent;
  border-radius: 5px;
  font-size: clamp(12px, 1.1vw, 14px);
  transition: all 0.3s;

  &:hover {
    background-color: var(--button-background-color);
    color: var(--font-color-white);
    border-radius: 2px;
  }
}

.weight__short-description {
  margin-top: 20px;
}

.td__ttx {
  padding-left: 5px;
}

.thead {
  font-size: clamp(14px, 1.5vw, 20px);
  padding-bottom: 5px;
}

.btns__short-description {
  display: flex;
  gap: 20px;
}

.btn__download-docs,
.btn__arrange {
  width: 270px;
  height: 45px;
  border-radius: 10px;
  font-size: clamp(12px, 1.15vw, 16px);
  transition: all 0.3s;
}

.btn__arrange {
  text-align: center;
  line-height: 45px;
}

.btn__download-docs {
  background-color: transparent;
  border: 1px solid var(--button-color-red);
  color: var(--button-color-red);
  text-align: center;
  line-height: 45px;

  &:hover {
    background-color: var(--button-background-color);
    color: var(--font-color-white);
    border-radius: 2px;
  }
}

.btn__arrange {
  background-color: var(--button-color-red);
  border: 1px solid var(--button-color-red);
  color: white;

  &:hover {
    background-color: var(--button-color-hover);
    border: 1px solid var(--button-color-hover);
    color: var(--font-color-white);
    border-radius: 2px;
  }
}

/* Section info */
.title__info {
  font-size: clamp(26px, 2vw, 36px);
  text-transform: uppercase;
  font-weight: normal;
  text-align: center;
  margin-bottom: 40px;

  &:after {
    content: "";
    display: block;
    width: 100px;
    height: 5px;
    background-color: var(--line-color);
    margin: 0 auto;
    margin-top: 10px;
  }
}

.table__ttx {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.text__info {
  max-width: 1140px;
  margin: 0 auto;
  font-size: var(--font-size);
}

.text__info_list {
  padding: 5px 0;
}

.text__info_list::marker {
  color: #000000;
  list-style: disc;
}

/* Section specifications */
.title__specifications {
  font-size: clamp(26px, 2vw, 36px);
  text-transform: uppercase;
  font-weight: normal;
  text-align: center;
  margin-bottom: 40px;

  &:after {
    content: "";
    display: block;
    width: 100px;
    height: 5px;
    background-color: var(--line-color);
    margin: 0 auto;
    margin-top: 10px;
  }
}

.table__specifications {
  display: flex;
  max-width: 1000px;
  justify-content: space-evenly;
  margin: 0 auto;
  gap: 50px;
}

.table__specifications_title td {
  font-size: var(--font-size-table-head);
  text-transform: uppercase;
  font-weight: 600;
  padding: 0px 70px;
  padding-top: 30px;
  padding-bottom: 5px;
}

.table__specifications_line td {
  padding: 5px 70px;
  white-space: pre-line;
}

.thead__table {
  font-size: 24px;
  padding-top: 10px;
}

.body__table_ttx tr {
  padding-left: 200px;
}

/* Section gabarits*/
.title__gabarits {
  font-size: clamp(26px, 2vw, 36px);
  text-transform: uppercase;
  font-weight: normal;
  text-align: center;
  margin-bottom: 40px;

  &:after {
    content: "";
    display: block;
    width: 100px;
    height: 5px;
    background-color: var(--line-color);
    margin: 0 auto;
    margin-top: 10px;
  }
}

.content__gabarits {
  display: flex;
  justify-content: center;
  gap: 225px;
  align-items: flex-start;
}

.images__gabarits img {
  display: block;
  width: 400px;
  margin-bottom: 10px;
}

.table__gabarits td {
  font-size: var(--font-size);
  padding-bottom: 15px;
}

.table__gabarits tr td {
  padding-left: 100px;
}

@media (width <= 1800px) {
  .content-hero__short-description img {
    width: 900px;
  }
}

@media (width <= 1600px) {
  .content-hero__short-description img {
    width: 700px;
  }
}

@media (width <= 1560px) {
  .content__gabarits {
    gap: 150px;
  }
}

@media (width <= 1500px) {
  .images__gabarits img {
    width: 400px;
  }
}

@media (width <= 1400px) {
  .short-start {
    gap: 40px;
  }

  .btns__short-description {
    gap: 10px;
  }

  .btn__download-docs,
  .btn__arrange {
    width: 200px;
  }
}

@media (width <= 1350px) {
  .title__info,
  .title__specifications,
  .title__gabarits {
    margin-bottom: 20px;
  }
}

@media (width <= 1250px) {
  .table__specifications_title td {
    padding: 0px 40px;
    padding-top: 20px;
    font-weight: 600;
  }

  .table__specifications_line td {
    padding: 5px 40px;
  }

  .table__gabarits tr td {
    padding-left: 50px;
  }
}

@media (width <= 1240px) {
  .short-start {
    gap: 10px;
  }

  .btn__short-start {
    width: 170px;
  }

  .table__ttx {
    margin-bottom: 20px;
  }

  .btns__short-description {
    flex-direction: column;
  }
}

@media (width <= 1130px) {
  .btn__short-start:hover {
    background-color: transparent;
    border-radius: 5px;
    color: var(--button-color-red);
  }

  .btn__download-docs:hover {
    background-color: transparent;
    color: var(--button-color-red);
    border-radius: 10px;
  }

  .btn__arrange:hover {
    background-color: var(--button-color-red);
    border: 1px solid var(--button-color-red);
    color: white;
    border-radius: 10px;
  }

  .content__gabarits {
    gap: 70px;
  }
}

@media (width <= 1000px) {
  .items-text__nav {
    gap: 30px;
  }

  .content-hero__short-description img {
    width: 600px;
  }

  .content__gabarits {
    gap: 30px;
  }
}

@media (width <= 930px) {
  .content__gabarits {
    flex-direction: column;
  }

  .images__gabarits {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}

@media (width <= 900px) {
  .content-hero__short-description {
    flex-direction: column;
  }

  .content-hero__short-description img {
    width: 100%;
  }

  .text__short-description {
    justify-content: center;
  }

  .short-start,
  .btns__short-description {
    justify-content: center;
  }

  .weight__short-description {
    display: flex;
    justify-content: center;
  }

  .btns__short-description {
    flex-direction: inherit;
  }
}

@media (width <= 870px) {
  .images__gabarits {
    flex-direction: column;
    align-items: center;
  }

  .images__gabarits img {
    width: 100%;
  }

  .table__gabarits {
    margin-top: 30px;
  }
}

@media (width <= 460px) {
  .table__specifications_title td {
    padding: 0px 30px;
    padding-top: 20px;
  }

  .table__specifications_line td {
    padding: 5px 30px;
  }

  .table__gabarits tr td {
    padding-left: 30px;
  }
}

@media (width <= 400px) {
  .table__specifications_title td {
    padding: 0 10px;
    padding-top: 20px;
  }

  .table__specifications_line td {
    padding: 2px 10px;
  }

  .table__gabarits tr td {
    padding-left: 10px;
  }
}

@media (width <= 350px) {
  .table__gabarits td {
    padding-bottom: 10px;
  }
}
