.contact {
  margin-top: 100px;
}

.title__contact {
  font-size: clamp(26px, 2vw, 36px);
  text-transform: uppercase;
  font-weight: normal;
}

.title__contact::after {
  content: '';
  display: block;
  width: 100px;
  height: 5px;
  background-color: var(--line-color);
  margin-top: 10px;
}
