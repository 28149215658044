.catalog {
  margin-top: 100px;
}

.title__catalog {
  font-size: clamp(26px, 2vw, 36px);
  text-transform: uppercase;
  font-weight: normal;
}

.title__catalog::after {
  content: "";
  display: block;
  width: 100px;
  height: 5px;
  background-color: var(--line-color);
  margin-top: 10px;
}

.items__catalog {
  display: grid;
  grid-template-columns: repeat(4, 400px);
  justify-content: space-around;
  gap: 30px;
  margin-top: 40px;
}

.item__catalog {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--line-color);
  border-radius: 20px;
  padding: 30px 55px;
  transition: all 0.3s;
}

.item__catalog:hover {
  border-radius: 10px;
  transform: scale(1.03);
}

.item__catalog img {
  height: 190px;
  transition: all 0.3s;
}

.item__catalog img:hover {
  transform: scale(1.03);
}

.title__item {
  font-size: clamp(16px, 1.3vw, 22px);
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 40px;
}

.title__item::after {
  content: "";
  display: block;
  width: calc(100% - 50%);
  height: 5px;
  background-color: var(--line-color);
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ttx__item {
  text-align: center;
  margin-bottom: 30px;
}

.weight__ttx p,
.load__ttx p {
  display: inline;
  font-size: clamp(10px, 1vw, 15px);
  font-weight: 100;
}

.item__btn {
  width: 200px;
  height: 50px;
  background-color: var(--button-background-color);
  border: 1px solid var(--button-color-red);
  border-radius: 15px;
  color: var(--font-color-white);
  font-size: clamp(10px, 1.5vw, 15px);
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.3s;
  text-align: center;
  line-height: 50px;
}

.item__btn:hover {
  border-radius: 10px;
  background-color: var(--button-color-hover);
  border: 1px solid var(--button-color-hover);
}

@media (width <= 1890px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(4, 370px);
  }
}

@media (width <= 1800px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(4, 350px);
  }
}

@media (width <= 1770px) {
  .item__catalog img {
    height: 170px;
  }
}

@media (width <= 1700px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(4, 320px);
  }
}

@media (width <= 1650px) {
  .item__catalog {
    padding: 30px 45px;
  }

  .item__catalog img {
    height: 160px;
  }
}

@media (width <= 1600px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(4, 300px);
  }
}

@media (width <= 1500px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(4, 290px);
  }

  .item__catalog img {
    height: 150px;
  }
}

@media (width <= 1350px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(3, 380px);
    margin-top: 20px;
  }
}

@media (width <= 1300px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(3, 340px);
  }
}

@media (width <= 1180px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(3, 320px);
  }
}

@media (width <= 1120px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(3, 300px);
  }
}

@media (width <= 1100px) {
  /* .items__catalog {
    display: grid;
    grid-template-columns: repeat(3, 300px);
  } */

  .item__catalog:hover {
    border-radius: 20px;
    transform: scale(1);
  }

  .item__catalog img:hover {
    transform: scale(1);
  }

  .item__btn:hover {
    border-radius: 15px;
    background-color: var(--button-color-red);
    border: 1px solid var(--button-color-red);
  }
}

@media (width <= 1075px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(3, 280px);
  }
}

@media (width <= 1000px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(3, 250px);
  }

  .item__catalog img {
    height: 130px;
  }
}

@media (width <= 920px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(3, 240px);
  }
}

@media (width <= 900px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(2, 350px);
  }

  .item__catalog img {
    height: 150px;
  }
}

@media (width <= 840px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(2, 320px);
  }
}

@media (width <= 800px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(2, 310px);
  }
}

@media (width <= 750px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(2, 280px);
  }
}

@media (width <= 700px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(2, 290px);
  }
}

@media (width <= 680px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(2, 260px);
  }
}

@media (width <= 640px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(2, 240px);
  }

  .item__catalog img {
    height: 130px;
  }
}

@media (width <= 620px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(2, 230px);
  }
}

@media (width <= 580px) {
  .items__catalog {
    display: grid;
    grid-template-columns: repeat(1, 100%);
  }

  .item__catalog img {
    height: 150px;
  }
}
